<div>
  <div
    id="image-slider"
    class="carousel slide h-75 padding-bottom"
    data-ride="carousel"
  >
    <!-- indicators dot nav -->
    <ol class="carousel-indicators">
      <li data-target="#image-slider" data-slide-to="0" class="active"></li>
      <li data-target="#image-slider" data-slide-to="1"></li>
      <!-- <li data-target="#image-slider" data-slide-to="2"></li> -->
    </ol>
    <!-- wrappers for slides -->
    <div class="carousel-inner">
      <div class="carousel-item active home">
        <img
          class="d-block w-100"
          src="assets/images/2020-SL550.jpg"
          alt="Third slide"
        />
      </div>
      <div class="carousel-item">
        <img
          class="d-block w-100"
          src="assets/images/e-class.jpg"
          alt="Mercedes-Benz"
        />
        <div class="carousel-caption">
          <h1>
            <!-- Mercedes-Benz -->
          </h1>
        </div>
      </div>
      <div class="carousel-item">
        <img
          class="d-block w-100"
          src="assets/images/g-class.jpg"
          alt="Second slide"
        />
      </div>
    </div>
    <!-- controls or next and previous buttons -->
    <a
      class="carousel-control-prev"
      href="#image-slider"
      role="button"
      data-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a
      class="carousel-control-next"
      href="#image-slider"
      role="button"
      data-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>

  <div>
    <h2>Welcome To Your Local Mercedes-Benz Specialists</h2>
  </div>

  <hr class="main" />

  <div class="container-fluid">
    <div class="row">
      <div class="col-xs-12 col-sm-6 col-sm-push-6">
        <h2>We strive to be the best in the industry!</h2>
        <p>
          Here at VM Foreign Car Sales and Service, we put our customer first.
          We understand how hard it is to find a trusted service shop for your
          vehicle, which is why Viorel Malea created this family owned business.
        </p>
      </div>

      <div class="col-xs-12 col-sm-6 col-sm-push-6">
        <div
          id="review-slider"
          class="carousel slide w-100 h-100"
          data-ride="carousel"
        >
          <!-- indicators dot nav -->
          <ol class="carousel-indicators">
            <li
              data-target="#review-slider"
              data-slide-to="0"
              class="active"
            ></li>
            <li data-target="#review-slider" data-slide-to="1"></li>
            <li data-target="#review-slider" data-slide-to="2"></li>
            <li data-target="#review-slider" data-slide-to="3"></li>
            <li data-target="#review-slider" data-slide-to="4"></li>
            <li data-target="#review-slider" data-slide-to="5"></li>
            <li data-target="#review-slider" data-slide-to="6"></li>
            <li data-target="#review-slider" data-slide-to="7"></li>
            <li data-target="#review-slider" data-slide-to="8"></li>
            <li data-target="#review-slider" data-slide-to="9"></li>
            <li data-target="#review-slider" data-slide-to="10"></li>
            <li data-target="#review-slider" data-slide-to="11"></li>
          </ol>
          <!-- wrappers for slides -->
          <div class="carousel-inner">
            <!-- <div class="carousel-item active">
                    <img class="d-block w-100 h-100" src="assets/reviews/review_1.PNG" alt="First slide">
                </div>
                <div class="carousel-item">
                    <img class="d-block w-100 h-100" src="assets/reviews/review_2.PNG" alt="First slide">
                </div> -->
            <div class="carousel-item active">
              <img
                class="d-block w-100 h-100"
                src="assets/reviews/review_3.PNG"
                alt="First slide"
              />
            </div>
            <div class="carousel-item">
              <img
                class="d-block w-100 h-100"
                src="assets/reviews/review_4.PNG"
                alt="First slide"
              />
            </div>
            <div class="carousel-item">
              <img
                class="d-block w-100 h-100"
                src="assets/reviews/review_5.PNG"
                alt="First slide"
              />
            </div>
            <div class="carousel-item">
              <img
                class="d-block w-100 h-100"
                src="assets/reviews/review_6.PNG"
                alt="First slide"
              />
            </div>
            <div class="carousel-item">
              <img
                class="d-block w-100 h-100"
                src="assets/reviews/review_7.PNG"
                alt="First slide"
              />
            </div>
            <div class="carousel-item">
              <img
                class="d-block w-100 h-100"
                src="assets/reviews/review_8.PNG"
                alt="First slide"
              />
            </div>
            <div class="carousel-item">
              <img
                class="d-block w-100 h-100"
                src="assets/reviews/review_9.PNG"
                alt="First slide"
              />
            </div>
            <div class="carousel-item">
              <img
                class="d-block w-100 h-100"
                src="assets/reviews/review_10.PNG"
                alt="First slide"
              />
            </div>
            <div class="carousel-item">
              <img
                class="d-block w-100 h-100"
                src="assets/reviews/review_11.PNG"
                alt="First slide"
              />
            </div>
            <div class="carousel-item">
              <img
                class="d-block w-100 h-100"
                src="assets/reviews/review_12.PNG"
                alt="First slide"
              />
            </div>
            <div class="carousel-item">
              <img
                class="d-block w-100 h-100"
                src="assets/reviews/review_13.PNG"
                alt="First slide"
              />
            </div>
          </div>
          <!-- controls or next and previous buttons -->
          <a
            class="carousel-control-prev"
            href="#review-slider"
            role="button"
            data-slide="prev"
          >
            <span
              class="carousel-control-prev-icon black"
              aria-hidden="true"
            ></span>
            <span class="sr-only">Previous</span>
          </a>
          <a
            class="carousel-control-next"
            href="#review-slider"
            role="button"
            data-slide="next"
          >
            <span
              class="carousel-control-next-icon black"
              aria-hidden="true"
            ></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
