import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'VMMercedes';
  showHome = true;
  showAbout = false;
  showContact = false;
  showLocation = false;
  showServices = false;

  setHome() {
    this.showHome = true;
    this.showAbout = false;
    this.showContact = false;
    this.showLocation = false;
    this.showServices = false;
  }

  setContact() {
    this.showHome = false;
    this.showAbout = false;
    this.showContact = true;
    this.showLocation = false;
    this.showServices = false;
  }

  setAbout() {
    this.showHome = false;
    this.showAbout = true;
    this.showContact = false;
    this.showLocation = false;
    this.showServices = false;
  }

  setLocation() {
    this.showHome = false;
    this.showAbout = false;
    this.showContact = false;
    this.showLocation = true;
    this.showServices = false;
  }

  setServices() {
    this.showHome = false;
    this.showAbout = false;
    this.showContact = false;
    this.showLocation = false;
    this.showServices = true;
  }
}
