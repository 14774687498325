<nav class="navbar navbar-expand-lg sticky">
  <a class="navbar-brand" href="#"></a>
  <button
    class="navbar-toggler navbar-light"
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a
          class="nav-link"
          href="#home"
          (click)="setHome()"
          data-toggle="collapse"
          data-target=".navbar-collapse.show"
          >Home <span class="sr-only">(current)</span></a
        >
      </li>

      <li class="nav-item">
        <a
          class="nav-link"
          href="#about"
          (click)="setAbout()"
          data-toggle="collapse"
          data-target=".navbar-collapse.show"
          >About <span class="sr-only">(current)</span></a
        >
      </li>

      <li class="nav-item">
        <a
          class="nav-link"
          href="#services"
          (click)="setServices()"
          data-toggle="collapse"
          data-target=".navbar-collapse.show"
          >Services <span class="sr-only">(current)</span></a
        >
      </li>

      <li class="nav-item">
        <a
          class="nav-link"
          href="#location"
          (click)="setLocation()"
          data-toggle="collapse"
          data-target=".navbar-collapse.show"
          >Location <span class="sr-only">(current)</span></a
        >
      </li>

      <li class="nav-item">
        <a
          class="nav-link"
          href="#contact"
          (click)="setContact()"
          data-toggle="collapse"
          data-target=".navbar-collapse.show"
          >Contact <span class="sr-only">(current)</span></a
        >
      </li>
    </ul>
  </div>
</nav>

<div class="main">
  <body class="main">
    <h1 class="MainPage">VM Foreign Car Sales and Service, Inc.</h1>
    <app-home *ngIf="showHome"></app-home>
    <app-about *ngIf="showAbout"></app-about>
    <app-contact *ngIf="showContact"></app-contact>
    <app-location *ngIf="showLocation"></app-location>
    <app-services *ngIf="showServices"></app-services>
  </body>
</div>
