<hr class="main" />

<h2>Services We offer</h2>

<div class="container-fluid">
  <div class="row">
    <div class="col-xs-12 col-sm-6 col-sm-push-6">
      <ul>
        <li>Scheduled Maintenance (A and B service)</li>
        <li>
          A/C service (A/C maintenance, compressor, dryer, leak detection)
        </li>
        <li>Brakes</li>
        <li>Cooling system (radiators, water pumps, coolant leaks)</li>
        <li>Electrical (Mercedes Benz Xentry and DAS computer diagnostic)</li>
        <li>
          Suspension (air struts,ABC struts, control arms, trust rod arms, tie
          rod ends, sway bar/sway bar links)
        </li>
        <li>Check engine light diagnosis</li>
        <li>Drivability problems</li>
        <li>Transmission service and engine tune up</li>
        <li>Power steering (power steering pump, steering rack)</li>
      </ul>
    </div>
    <div class="col-xs-12 col-sm-6 col-sm-pull-6">
      <img src="assets/images/shop_1.jpg" class="center img-fluid" alt="" />
    </div>
  </div>
</div>
