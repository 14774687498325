<hr class="main" />

<h2>About the Shop!</h2>

<div class="">
    <div class="">
        <p>
            VM Foreign Car was established 40 plus years ago by Viorel Malea. He built his business on doing honest work, upholding strong values, and his integrity. That philosophy continues to present day. The Service Manager Bill Hobbs has been working at VM Foreign
            Car since the business was established in 1977. Viorel’s son, Christopher Malea, also works at the business. Christopher has a Mercedes technical automotive degree and has been working on Mercedes for many years now. Chris’ goal is to continue
            his father’s legacy by bringing forth present day knowledge/skills on Mercedes Benz and working alongside Bill’s vast years of experience. The shop is equipped with top of the line equipment and tools to tackle any problem. Customer service
            is a strong value that is upheld inside this shop and welcomes any face that enters.
        </p>
    </div>

    <div class="">
        <img class="center img-fluid" src="assets/images/the_crew.jpg" alt="" />
    </div>
</div>