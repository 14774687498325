<div>
  <img class="center img-fluid" src="assets/images/office.jpg" alt="" />
  <h2>Contact Us Here!</h2>
  <p>
    Phone: <a href="tel:(404)284-1481" class="btn-link">(404)284-1481</a>
    <br />
    E-mail:
    <a href="mailto:vmmercedes@bellsouth.net" class="link-primary"
      >vmmercedes@bellsouth.net</a
    >
  </p>

  <p>
    Hours
    <br />
    Monday - Friday: 6 AM to 2 PM
    <br />
    Saturday - Sunday: Closed
  </p>
</div>
